<template>
  <div id="app">
    <header id="home">
      <div class="container clearfix">
        <div class="logo fl">
          <img src="./assets/logo.png" height="37" alt="Alitas">
        </div>
        <nav>
          <ul>
            <li @click="scrollTo('tech')">Technical advantages</li>
            <li @click="scrollTo('comm')">Ecology</li>
            <li @click="scrollTo('support')">Game-support</li>
            <li @click="scrollTo('roadmap')">Roadmap</li>
            <li @click="scrollTo('faq')">FAQ</li>
            <li @click="scrollTo('faq')">Contact us</li>
            <li><a :href="`${publicPath}ALITAS_WhitePaper.pdf`" target="_blank">WhitePaper</a></li>
          </ul>
        </nav>
      </div>
    </header>

    <section class="intro" id="intro">
      <div class="container">
        <div class="desc fl">
          <h1>The Underlying Infrastructure of a New Generation of Value Networks</h1>
          <p>Alitas is dedicated to building a new generation of underlying trusted network protocols, and providing efficient, convenient, secure, and stable development and deployment environments to customers worldwide.</p>

          <p>The Alitas architecture has the potential to completely replace the traditional chain structure. With its disruptive breakthrough in the theory of traditional directed acyclic graph (DAG), Alitas architecture organizes blocks to achieve complete decentralization and completeness while achieving TPS up to 30,000+ per second, breaking the performance bottleneck of traditional consensus mechanisms.</p>
        </div>
        <div class="diagram fl">
          <img src="./assets/DAG-diagram.png" alt="">
        </div>
        <br style="clear:both">
      </div>
    </section>

    <section class="tech" id="tech">
      <ul class="container flex">
        <li>
          <h4>Star Drop Effect</h4>
          <p>Randomness like the star drop, introduces nodes to obtain the legitimacy of the transaction. Realize high security of transaction privacy.</p>
        </li>
        <li>
          <p>Break the traditional chain structure and design a new consensus mechanism on Alitas. While achieving complete decentralization and absolute security, it breaks through performance bottlenecks, and TPS can reach 30,000+ per second.</p>
          <h4>STAR LINK Structure</h4>
        </li>
      </ul>
    </section>

    <section class="algo" id="algo"> 
      <div class="container flex">
        <dl>
          <dt>Tolerance Algorithm</dt>
          <dd>The tolerance algorithm is used to replace consensus to solve data consistency. Through the original Alitas structure, the included nodes can obtain legal transaction references, realizing complete decentralization.</dd>
        </dl>
        <dl>
          <dt>No fork entanglement<br>(TANGLE)</dt>
          <dd>DAG transactions offer a number of advantages over distributed ledgers such as better scalability and faster speed. Ultimate verifiability of transactions however is difficult to achieve. Alitas solves this by utilising a Monte Carlo-based selection algorithm that constructs a fluid approximation of entanglement so that arbitrary transactions can be verified in less than 1 second.</dd>
        </dl>
      </div>
    </section>

    <section class="comm" id="comm">
      <h2>Alitas Business Community</h2>
      <h6>Next generation value network infrastructure</h6>
      <ul class="container flex">
        <li>
          <div>
            <p>Cross-chain protocols via Layer2 or sidechains</p>
            <h5>Offline Network</h5>
          </div>
        </li>
        <li>
          <div>
          <h5>GameFi</h5>
          <p>High-performance public chain with strong support for games</p>
          </div>
        </li>
        <li>
          <div>
          <h5>SocialFi</h5>
          <p>Data security is ensured by fragmentation and encryption technology. The unique ID identification technology prevents Sybil Attacks</p>
          </div>
        </li>
        <li>
          <div>
          <p>web3.0 compatible protocols, with more possibilities in the future</p>
          <h5>Web3.0</h5>
          </div>
        </li>
      </ul>
    </section>

    <section class="support" id="support">
      <h2>From Gamefi to the Metaverse</h2>
      <div class="container">
        <img src="./assets/1left.png" width="50%" alt=""><img src="./assets/2right.png" width="50%" alt="">
      </div>
    </section>

    <section class="roadmap" id="roadmap">
      <h2>Roadmap</h2>
      <ul class="upper flex">
        <li>
          <h6>Q2 of 2021</h6>
          <p>The "tolerance algorithm" of the Alitas <br>underlying layer has been completed</p>
        </li>
        <li>
          <h6>Q4 of 2021</h6>
          <p>The Alitas "Star Drop effect" <br>will be completed</p>
        </li>
        <li>
          <h6>Q2 of 2022</h6>
          <p>Development of the public chain, <br>wallet and smart contract IDE will <br>be completed and internal testing will begin</p>
        </li>
        <li>
          <h6>Q4 of 2022</h6>
          <p>Game SDK development completed, <br>Alpha version released for public testing</p>
        </li>
      </ul>
      <p class="dots">
        <b></b><b></b><b></b><b></b>
        <b></b><b></b><b></b><b></b>
      </p>
      <ul class="lower flex">
        <li>
          <h6>Q1 of 2021</h6>
          <p>Construction of the Alitas main <br>network has been completed</p>
        </li>
        <li>
          <h6>Q3 of 2021</h6>
          <p>Test the compatibility and stability <br>of the tolerance algorithm</p>
        </li>
        <li>
          <h6>Q1 of 2022</h6>
          <p>Development of the on-chain data <br>sharding, encrypted storage and <br>user identification system will be completed</p>
        </li>
        <li>
          <h6>Q3 of 2022</h6>
          <p>Preliminary completion of on-chain <br>governance-related smart contracts <br>and token economy system</p>
        </li>
      </ul>
    </section>

    <section class="faq" id="faq">
      <h2>FAQ</h2>
      <ul class="container">
        <li v-for="faq in faqs" v-bind:key="faq.answer">
          <div class="upper clearfix" @click="faq.fold=!faq.fold" :class="[faq.fold?'up':'down']">
            <p class="name fl" style="margin-left:24px">{{faq.quest}}</p>
            <p class="arr-btn fr"><i class="arrow-down"></i></p>
          </div>

          <div class="lower" v-show="faq.fold">
            <div class="intro" v-html="faq.answer"></div>
          </div>
        </li>
      </ul>
    </section>

    <footer>
      <h2>Contact us</h2>
      <div class="container">
        <ul>
          <li>
            <a href="mailto:hi@alitas.tech" target="_blank"><img src="./assets/Email.png" width="18" alt=""></a>
          </li>
          <li>
            <a href="https://twitter.com/AlitasTech" target="_blank"><img src="./assets/Twitter.png" width="18" alt=""></a>
          </li>
          <li>
            <a href="https://medium.com/@AlitasTech" target="_blank"><img src="./assets/Medium.png" width="18" alt=""></a>
          </li>
          <li>
            <a href="https://t.me/Alitastech" target="_blank"><img src="./assets/Telegram.png" width="18" alt=""></a>
          </li>
          <li>
            <a href="https://www.coingecko.com/en/coins/alitas" target="_blank"><img src="./assets/coingecko.png" width="18" alt=""></a>
          </li>
        </ul>
        <p>©2022 Copyright by Alitas</p>
      </div>
    </footer>
    <announcement />
  </div>
</template>

<script>
import Announcement from './components/Announcement.vue';
export default {
  name: 'App',
  components: {
    Announcement
  },
  data() {
		return {
      showTop: false,
      publicPath: process.env.BASE_URL,
      faqs: [
        {
          fold: 0,
          quest: `Any plans for listing on exchanges?`,
          answer: `<p>Currently ALT token is listed on indodax.com, cointiger, bibox and xt.com,  which strongly demonstrated its potential and strength in technology innovation. And currently we are under negotiation with some reputable exchanges. I think we’ll have good news very soon.</p>`
        },
        {
          fold: 0,
          quest: `What benefits do Alitas will bring to us?`,
          answer: `<p>Committed to open ecology, open application, and open cooperation with other ecosystems, the underlying Alitas infrastructure can be fully integrated with big data, cloud computing, artificial intelligence, 5G, and other technologies, and can seamlessly connect with other blockchain networks to realize a shared and open community. The major breakthroughs of Alitas aim to bring a more efficient, convenient, safe, and stable development and deployment environment to users around the globe.</p>`
        },
        {
          fold: 0,
          quest: `What is the vision of Alitas in the future does Alitas have any special plans to attract more users in the future?`,
          answer: `<p>ALITAS, higher transaction throughput (through parallel verification) can be achieved, and no transaction fees are charged. With the continuous development of Tangle, more and more participants will initiate transactions, the entire system will become more and more secure and fast, the confirmation time will be shortened, and transactions will be completed faster and faster.</p><p>In the ALITAS system, every participant in the network can trade and actively participate in consensus. To be more specific, you directly locate two transactions (main transaction and branch transaction), and indirectly locate other transactions in the child tangle. In this way, verification can be performed synchronously, and the network can remain completely decentralized, without the need for miners to pass on trust and to pay transaction fees.</p>`
        }
      ]

    }
  },
  mounted() {
    window.addEventListener("scroll",()=>{
        let scrollTop = document.documentElement.scrollTop;
        if(scrollTop > 100){
            this.showTop = true;
        }else{
            this.showTop = false;
        }
    }) 
  },
  methods: {
    scrollTo(id) {
      window.document.getElementById(id).scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss">
body {
  background-color: #000;
  color: #fff;
}

.container {
  width: 960px;
  margin: 0 auto;
  position: relative;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.arrow {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  &.up {
    transform: rotate(135deg);
  }
  &.down {
    transform: rotate(-45deg);
    margin-top: -20px;
  }
}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #000;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  width: 100%;
  height: 100px;
  background-color: #010101;
  padding-top: 16px !important;
  .logo {
    height: 100px;
    display: flex;
    align-items: center;
  }
  nav {
    font-size: 12px;
    margin-left: 32px;
    margin-top: 24px;
    ul {
      display: flex;
      li {
        // flex: 1;
        line-height: 2.5;
        margin-left: 40px;
        position: relative;
        cursor: pointer;
        &:hover {
          border-bottom: 5px solid #5F56FD;
        }
        a {
          color: #fff;
        }
      }
    }
  }
  
}

section {
  h2 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 48px;
  }
  &.intro {
    padding: 32px;
    background-image: url(./assets/head_bga.jpg);
    background-repeat: no-repeat;
    background-position: center 30%;
    background-size: 1500px 900px;
    .desc {
      padding: 32px 0;
      color: #fff;
      width: 50%;
      h1 {
        font-size: 32px;
        line-height: 36px;
        font-weight: bolder;
      }
      p {
        line-height: 20px;
        font-size: 14px;
        margin-top: 24px;
      }
    }
    .diagram {
      width: 50%;
      text-align: right;
      img {
        width: 90%;
      }
    }
  }

  &.tech {
    position: relative;
    background-image: url(./assets/lx.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1440px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
      background: #fff;
      border-radius: 0 40px 40px 0;
      z-index: -1;
    }
    ul {
      li {
        width: 50%;
        padding: 60px 0;
        h4 {
          font-size: 24px;
          font-weight: bolder;
          margin: 20px 0;
        }
        p {
          line-height: 1.2;
        }
        &:first-child {
          color: #000;
          p {
            padding-right: 120px ;
          }
        }
        &:last-child {
          text-align: right;
          p {
            padding-left: 120px ;
          }
        }
      }
    }
  }

  &.faq {
    padding: 80px 0;
    li {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #C1BEBB;
      margin: 24px 0;
      .upper {
        height: 48px;
        cursor: pointer;
        background-color: #d3d3d3;
        border-radius: 5px 5px 0 0;
        &.up {
          background-color: #fff;
          transition: background-color .5s;
        }
        .name {
          line-height: 48px;
          margin-left: 12px;
          font-size: 16px;
          color: #121212;
          font-weight: bolder;
          font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        }
        .arr-btn {
          position: relative;
          top: 32px;
          left: -20px;
        }
      }

      .lower {
        .intro {
          padding: 32px 24px;
          color: #000;
          background: #fff;
          box-shadow: inset 0px 3px 10px rgba(0, 0, 0, 0.13);
            p {
              margin-bottom: 20px; 
              line-height: 1.5;
              b{
                font-weight: bolder;
              }
            }
        }
      }
    }
  }

  &.algo {
    padding: 200px 0 350px;
    background-image: url(./assets/vvvv.jpg), url(./assets/lllllogo.png);
    background-repeat: no-repeat;
    background-position: 45% -120px, 48% 96%;
    background-size: contain, 1280px;
    dl {
      width: 50%;
      position: relative;
      dt {
        font-size: 24px;
        font-weight: bolder;
      }
      dd {
        line-height: 1.35;
      }
      &:first-child {
        padding-right: 64px;
        dt {
          margin-bottom: 56px;
        }
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 88%;
          width: 180px;
          height: 170px;
          background-image: url(./assets/T1.png);
          background-repeat: no-repeat;
          background-size: contain;
          background-position-x: -30px;
        }
      }
      &:last-child {
        padding-left: 64px;
        dt {
          line-height: 1.3;
          text-align: right;
          margin-bottom: 16px;
        }
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 100%;
          width: 180px;
          height: 170px;
          background-image: url(./assets/T2.png);
          background-repeat: no-repeat;
          background-size: contain;
          background-position-x: 30px;
        }
      }
    }
  }

  &.comm {
    background-color: #fff;
    color:#000;
    text-align: center;
    padding: 80px 0;
    background-image: url(./assets/line.png);
    background-repeat: no-repeat;
    background-position: center 170px;
    background-size: 960px 400px;
    .container {
      width: 1100px;
    }
    h2 {
      margin-bottom: 24px;
    }
    h6 {
      color: #616161;
    }
    ul {
      padding: 80px 0;
      position: relative;
      color: #fff;
      li {
        width: 255px;
        height: 280px;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
          background-image: url(./assets/a1.png);
          top: 24px;
        }
        &:nth-child(2) {
          background-image: url(./assets/a2.png);
        }
        &:nth-child(3) {
          background-image: url(./assets/a3.png);
        }
        &:last-child {
          background-image: url(./assets/a4.png);
          top: 24px;
        }
        h5 {
          font-size: 18px;
          font-weight: bolder;
          margin: 20px;
        }
        p {
          padding: 0 12px;
          line-height: 1.3;
        }
      }
      &::after {
        content: 'Other blockchain networks';
        position: absolute;
        left: -5px; right: 0;
        top: 95%;
        margin: auto;
        background: #EEEEEE;
        border-radius: 17px;
        width: 220px;
        height: 32px;
        line-height: 32px;
        font-size: 11px;
        color: #000;
        font-weight: bolder;
      }
    }
  }

  &.support {
    position: relative;
    padding: 120px 0;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 40px;
      width: 100%;
      background-color: #fff;
      background-image: url(./assets/00.png);
      background-size: 10%;
    }
    h2 {
      font-size: 32px;
      margin-bottom: 80px;
      background-image: url(./assets/From-Gamefi-to-the-Metaverse.png);
      background-repeat: no-repeat;
      background-size: 456px 23px;
      background-position: center;
      text-indent: -9999px;
    }
  }

   &.roadmap {
      position: relative;
      padding: 100px 0;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 5px;
        background: linear-gradient(90deg, #FFB8FB, #522A84, #48668A, #52CDBE, #5BC2EC);
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 2px;
        background:  linear-gradient(90deg, #D893E8, #EFDA49, #B4FFD3, #46EAE1);
      }
      h2 {
        margin-bottom: 60px !important;
      }
      h2::after {
        content: '';
        display: block;
        width: 100%;
        position: relative;
        top: 180px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        z-index: -1;
      }
      p.dots {
        width: 1280px;
        margin: 24px auto;
        b {
          display: inline-block;
          width: 12.5%;
          height: 40px;
          background-image: url(./assets/fenq.png);
          background-repeat: no-repeat;
          background-size: contain;
          &:nth-of-type(n+5) {
            background-image: url(./assets/lvq.png);
          }
        }
      }
      ul {
        width: 1280px;
        margin: 0 auto;
        position: relative;
        &.upper {
          left: 160px;
        }
        li {
          width: 25%;
          h6 {
            color: #D893E8;
            font-weight: bolder;
            margin-bottom: 18px;
          }
          p {
            font-size: 11px;
            line-height: 14px;
          }
          &:nth-of-type(n+3) h6 {
            color: #2AF1C0;
          }
        }
      }
    }
}

footer {
  width: 100%;
  height: 100px;
  background-color: #000;
  text-align: center;
  padding: 50px 0 100px !important;
  h2 {
    font-size: 24px;
  }
  ul {
    display: flex;
    width: 300px;
    margin: 32px auto;
    li {
      flex: 1;
    }
  }
}

</style>
